html, body, h1, h2, h3, h4, h5, h6 {
  font-family: "Poppins" sans-serif; }

@media only screen and (max-width: 992px) {
  h1, .h1 {
    font-size: 1.8rem; }
  h2, .h2 {
    font-size: 1.55rem; }
  h3, .h3 {
    font-size: 1.35rem; }
  h4, .h4 {
    font-size: 1.1rem; }
  h5, .h5 {
    font-size: 0.95rem; }
  h6, .h6 {
    font-size: 0.7rem; } }

.no-shadow {
  box-shadow: none !important; }

.input_group.transparent {
  border: 1px solid #66CBC9;
  border-radius: 5px; }

.pac-container {
  z-index: 1501 !important; }

.ag-cell-focus, .ag-cell-no-focus {
  border: none !important; }

.btn-group.btn-group-spacer > .btn {
  border-radius: 0.25rem !important;
  margin-left: 3px !important; }

/* This CSS is to not apply the border for the column having 'no-border' class */
.no-border.ag-cell:focus {
  border: none !important;
  outline: none; }

.text-active {
  color: #66cbc9 !important; }

.list-group-item.active {
  background-color: #66CBC9;
  border-color: #66CBC9; }

.flex-1 {
  flex: 1; }

.btn-group > .btn {
  min-width: 95px;
  max-width: 110px; }

.btn-theme {
  color: #fff;
  background-color: #66CBC9;
  border-color: #66CBC9; }

.btn-theme-secondary {
  background-color: #FF485B;
  border-color: #FF485B;
  color: #fff; }

.btn-outline-theme-secondary {
  color: #FF485B;
  background-color: transparent;
  background-image: none;
  border-color: #FF485B; }

.btn-outline-theme-secondary:hover {
  color: #fff;
  background-color: #FF485B;
  border-color: #FF485B; }

.btn-outline-theme-secondary:focus, .btn-outline-theme-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-theme-secondary.disabled, .btn-outline-theme-secondary:disabled {
  color: #FF485B;
  background-color: transparent; }

.btn-outline-theme-secondary:not([disabled]):not(.disabled):active, .btn-outline-theme-secondary:not([disabled]):not(.disabled).active,
.show > .btn-outline-theme-secondary.dropdown-toggle {
  color: #fff;
  background-color: #FF485B;
  border-color: #FF485B;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-theme {
  color: #66CBC9;
  background-color: transparent;
  background-image: none;
  border-color: #66CBC9; }

.btn-outline-theme:hover {
  color: #fff;
  background-color: #66CBC9;
  border-color: #66CBC9; }

.btn-outline-theme:focus, .btn-outline-theme.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-theme.disabled, .btn-outline-theme:disabled {
  color: #66CBC9;
  background-color: transparent; }

.btn-outline-theme:not([disabled]):not(.disabled):active, .btn-outline-theme:not([disabled]):not(.disabled).active,
.show > .btn-outline-theme.dropdown-toggle {
  color: #fff;
  background-color: #66CBC9;
  border-color: #66CBC9;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.bg-secondary {
  background-color: #f2f2f2 !important; }

.flex-1 {
  flex: 1; }

.no-scroll {
  overflow: hidden; }

.btn-square {
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center; }

.text-highlight {
  color: #00bcd4; }

.border-right {
  border-right: 2px solid #f2f2f2; }

.shadow {
  -webkit-box-shadow: 0 1px 15px 1px rgba(81, 77, 92, 0.08);
  box-shadow: 0 1px 15px 1px rgba(81, 77, 92, 0.08); }

.styled-list .list-group-item {
  border-radius: 0px !important;
  border: none; }

.styled-list .list-group-item span {
  flex: 1;
  justify-content: stretch;
  align-items: center; }

.styled-list li.table-header {
  background: transparent !important;
  border-bottom: 1px solid #f2f2f2; }

.styled-list .list-group-item:not(.table-header):hover {
  background-color: #e2e2e2 !important; }

.styled-list li:nth-child(even) {
  background-color: #f2f2f2; }

.styled-list li:nth-child(odd) {
  background-color: transparent; }

.auto-scroll-handle {
  min-height: 0;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1; }

body {
  background: #f2f2f2; }

.position-relative {
  position: relative; }

.scroll-view {
  flex: 1;
  min-height: 0;
  display: flex;
  overflow: auto; }

.content-wrapper {
  padding: 5px;
  overflow-x: hidden;
  padding-top: 4.3rem; }

.btn {
  cursor: pointer; }

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: none; }

.pointer {
  cursor: pointer; }

.btn-circle {
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  transition: background 0.3s; }

.btn-icon-only {
  display: flex;
  align-items: center;
  justify-content: center; }

/*.........Widget........*/
.widget {
  background-color: transparent;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  border: none; }

.card-header {
  padding: 0.75rem 0rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  display: flex;
  align-items: center;
  justify-content: space-between; }

.card-caption {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.card-title {
  display: flex;
  flex-direction: column;
  margin: 0; }

.card-title > .main-title {
  font-size: 1rem; }

/*.........................*/
/*........Card.............*/
.card {
  -webkit-box-shadow: 0 1px 15px 1px rgba(81, 77, 92, 0.08);
  box-shadow: 0 1px 15px 1px rgba(81, 77, 92, 0.08);
  border-radius: .25rem; }

.flat .widget.card {
  border: 1px solid rgba(0, 0, 0, 0.11);
  box-shadow: none;
  -webkit-box-shadow: none; }

.card-caption {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.card-title {
  display: flex;
  flex-direction: column; }

.card-title > .main-title {
  font-size: 1rem;
  font-weight: 600; }

.card-title > .sub-title {
  font-size: 0.85rem; }

.card-caption .card-icon + .card-title {
  margin-left: 5px; }

/*......................*/
/*..................List ...................*/
.list-caption {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.list-title {
  display: flex;
  flex-direction: column; }

.list-title > .main-title {
  font-size: 1rem;
  font-weight: 600; }

.list-title > .sub-title {
  font-size: 0.85rem; }

/*..................................*/
/*............Action Buttons.................*/
.action-buttons {
  display: flex;
  align-items: center;
  justify-content: center; }

.action-buttons > .btn-circle {
  height: 27px;
  width: 27px; }

.action-buttons > .btn:hover {
  background-color: rgba(0, 0, 0, 0.125); }

.action-buttons > .btn {
  margin-left: 5px; }

.action-buttons > .btn > i {
  font-size: 14px; }

/*....................................*/
/*..........Login Page..........*/
.login-page, .registration-page {
  background-image: linear-gradient(to right top, #012129, #00202b, #001f2d, #011e2f, #051d30); }

.login-form-div-appear {
  opacity: 0;
  -webkit-transform: scale(0.5); }

.login-form-div-appear.login-form-div-appear-active {
  opacity: 1;
  -webkit-transform: scale(1);
  transition: all 300ms ease-in; }

/*.......................*/
/*...........Tab Filter Button..............*/
.btn-tab-filter > .btn-outline-info {
  color: #17a2b8;
  background-color: white; }

.btn-tab-filter > .btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-tab-filter {
  position: absolute; }

.btn-tab-filter > .btn {
  line-height: 1;
  font-size: 12px; }

.btn-tab-filter.header > .btn {
  line-height: 2;
  font-size: 15px;
  z-index: 1; }

/*..............................*/
/*........Tab ..........*/
.tab-underlined.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  border-bottom: 1px solid;
  color: #007bff;
  border-radius: 0px;
  background-color: transparent; }

.tab-underlined.nav-pills .nav-link, .nav-pills .show > .nav-link {
  font-size: 12px;
  cursor: pointer; }

.tab-pane.fade {
  display: none !important; }

/*...........................*/
/*..........Action Button.......*/
.action-btn.loading .loading-dots {
  display: block; }

.action-btn.loading .text {
  display: none; }

.loading-dots {
  display: none;
  z-index: 5; }
  .loading-dots .dot {
    display: inline-flex;
    margin-left: 0.2em;
    margin-right: 0.2em;
    position: relative;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background: #66CBC9;
    opacity: 0;
    animation: showHideDot 2.5s ease-in-out infinite; }
    .loading-dots .dot.one {
      animation-delay: 0.2s; }
    .loading-dots .dot.two {
      animation-delay: 0.4s; }
    .loading-dots .dot.three {
      animation-delay: 0.6s; }

@keyframes showHideDot {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  60% {
    opacity: 1; }
  100% {
    opacity: 0; } }
